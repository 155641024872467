import React, { useEffect, useState } from "react"
import WebsiteHelper from "../../../helpers/WebsiteHelper"

const HeavyCustom = ({ setDisplay }) => {
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		const iframe = document.getElementById("iframeV2")
		if(iframe && iframe.parentNode) {
			iframe.addEventListener("load", onLoad)
		}
	}, [])

	function onLoad() {
		setLoaded(true)
	}

	useEffect(() => {
		if (loaded) {
			sendInitialMessageToIframe();
		}
	}, [loaded]);

	const sendInitialMessageToIframe = () => {
		const iframeWindow = window.frames.iframeV2;
		if (iframeWindow) {
			iframeWindow.postMessage(
				{
					token: localStorage.getItem("token"),
				},
				process.env.REACT_APP_FRONT_V2_DOMAIN
			);
		}
	};

	useEffect(() => {
		function receiveMessage(event) {
		  	if (event.origin !== process.env.REACT_APP_FRONT_V2_DOMAIN) return
			if(window.self === window.top) {
				if(event.data.goToMain) {
					setDisplay(0)
				} else if(event.data.goToHome) {
					WebsiteHelper.goTo(this, "/admin/orders")
				} else if(event.data.goToEstimate) {
					const iframe = document.getElementById("iframeV2")
					if (iframe) {
						iframe.src = `${process.env.REACT_APP_FRONT_V2_DOMAIN}/heavy-custom-estimate`;

						// Use a direct reference to the load event to ensure proper timing
						const handleIframeLoad = () => {
							sendInitialMessageToIframe();
							// Clean up the listener after it's triggered to avoid memory leaks
							iframe.removeEventListener("load", handleIframeLoad);
						};

						iframe.addEventListener("load", handleIframeLoad);
					}
				}
		  	} else if(event.data.goToMain || event.data.goToHome) {
				window.location.href = process.env.REACT_APP_WORDPRESS_MAIN_DOMAIN
		  	}

			if(event.data.goToContact) {
				window.location.href = process.env.REACT_APP_WORDPRESS_MAIN_DOMAIN + "/contactez-nous"
			}
		}
		window.addEventListener("message", receiveMessage, false)

		// N'oubliez pas de supprimer l'écouteur d'événements lorsque le composant est démonté
		return () => {
			window.removeEventListener("message", receiveMessage, false)
		}
	}, [])

	useEffect(() => {
		if (loaded) {
			let win = window.frames.iframeV2
			win.postMessage({
				token: localStorage.getItem("token")
			}, process.env.REACT_APP_FRONT_V2_DOMAIN)
		}
	}, [loaded])

	return (
		<div className="iframe-wrapper"
				 style={{ height: "99vh"}}
		>
			{!loaded &&
          <div className="loader">
              Chargement...
          </div>
			}
			<iframe
				id='iframeV2'
				style={{ width: "100%", height: "100%", border: "none" }}
				className="iframe-V2"
				src={`${process.env.REACT_APP_FRONT_V2_DOMAIN}/heavy-custom`}
				name="iframeV2"
			/>
		</div>

	)
}

export default HeavyCustom