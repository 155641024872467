
import React from "react";
import classnames from "classnames";
import $ from "jquery";
import Slider from "nouislider";
import queryString from 'query-string';

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    InputGroupText,
    InputGroupAddon,
    InputGroup,
    Button,
    ButtonGroup,
    Badge,
    Input,
    Table,
    Alert,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal
} from "reactstrap";
import SimpleHeader from '../../../../components/Headers/SimpleHeader'
import SearchService from '../../../../proxies/SearchService'
import { injectIntl , FormattedMessage } from 'react-intl';
import FormValidationComponent from "../FormValidationComponent";
import ReactSlidingPane from "react-sliding-pane";
import FormGroupInput from "../form/FormGroupInput"
import FormInput from '../FormInput'
import Title2 from '../../../components/common/title2'
import Title3 from '../../../components/common/title3'
import Title4 from '../../../components/common/title4'
import DefaultButton from '../../../components/common/defaultButton'
import Message from "../../../components/common/message"
import Rating from "../../../components/rating/rating"
import FormLoadingIndicator from "../FormLoadingIndicator";
import PortalSearchForm from "../../../components/search/portalSearchForm";
import OrderWorkflow from "../../../components/workflow/order/orderWorkflow";
import loginStep from "../../../components/workflow/account/loginStep";
import DefaultImage from "../../../components/common/defaultImage";
import DateHelper from "../../../../helpers/DateHelper";
import DateLabel from "../../../components/common/dateLabel";
import ImageWithRescue from "../Image/ImageWithRescue";
import Config from "../../../../config";
import Resources from "../../../../resources";
import SecurityService from "../../../../proxies/SecurityService";
import StringHelper from "../../../../helpers/StringHelper";
import DefaultInput from "../../../components/common/defaultInput";
import DefaultLink from "../../../components/common/defaultLink";
import WebsiteHelper from "../../../../helpers/WebsiteHelper";
import CallForBidsWorkflow from "../../../components/workflow/callForBids/callForBidsWorkflow";
import Search from "../../../../V2/Page/Search/Search";
import Offer from "../../../../V2/Page/Search/Offer";
import OfferOnline from "../../../../V2/Page/Search/OfferOnline";



var moment = require('moment');
require('moment/locale/fr');

class SearchResult extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.searchService = new SearchService()
        this.securityService = new SecurityService();
        this.portalSearchForm = null;
        this.childs = []
        this.searchId = null;
        this.field = "best";
        this.order = "best~DESC";
        this.state = {
            startOrder: false,
            specifiedPropertyValue: null,
            nbFiltersUsed: 0,
            properties: {},
            properties_count: null,
            search_id: null,
            filter_families: [],
            from: 1,
            nb_results_per_page: 10,
            slidingFormPanelIsVisible: true,
            slidingFiltersPanelIsVisible: false
        }
        this.startOrder = this.startOrder.bind(this)
        this.showModalConfirmation = this.showModalConfirmation.bind(this);
    }

    componentWillMount()
    {
        const searchId = this.props.match.params.searchId;
        
        this.setState(
            { search_id: searchId}
        )

        if (searchId)
        {
            // Récupération des filtres
            this.getFilters(searchId)
            // Récupération des résultats
            this.getSearchResults(searchId, this.state.from, this.state.nb_results_per_page)

            document.body.className = 'g-sidenav-show g-sidenav-pinned t2y-search-result-body';
        }
    }

    componentDidMount()
    {
    }

    sortResults(field, ascDesc)
    {
        this.order = field + "~" + ascDesc;
        this.field = field;
        this.getSearchResults(this.state.search_id, 1, this.state.nb_results_per_page)
    }

    getSearchResults(searchId, from, nb)
    {

        this.setState({
            from: from
        })

        const orderRule = this.order;
        this.searchService.getSearchResults(this, {
            search_id : searchId,
            from: from,
            nb: nb,
            order: orderRule
        }, (response) => {
            this.setState({
                allowed: response.allowed,
                nb_results: response.nb_results,
                results: response.results,
                request: response.request
            })

            if (response.request.allowed == false)
            {
                this.renderNotAllowedAlert(response.request);
            }
            else // Vérification du lancement d'appel d'offre
            {
                this.renderCallForBids(searchId, response);
            }

            const parsedQueryString = queryString.parse(window.location.search);
            if (parsedQueryString.resultId)
            {
                this.startOrderWorkflow(parsedQueryString.resultId)
            }
        })  
    }

    getFilters(searchId)
    {
        this.searchService.getSearchFilters(this, searchId, (response) => {

            var properties = this.state.properties;
        

            // Remplissage automatique des données du formulaire de recherche
            if (this.portalSearchForm)
            {
                this.portalSearchForm.setRequest(response.request)
            }

            if (this.portalSearchForm2)
            {
                this.portalSearchForm2.setRequest(response.request)
            }

            var propertiesCount = {};
            response.property_families.forEach(family => {
                family.properties.forEach(property => {
                    property.values.forEach(value => {
                        propertiesCount[value.value_as_id] = value.count;
                        if (value.used)
                        {
                            properties[value.value_as_id] = true;
                        }
                    })
                })
            })

            this.setState({
                properties: properties,
                request: response.request,
                properties_count: propertiesCount,
                filter_families: response.property_families
            })
        })
    }

    updateFilterCount()
    {
        var propertiesCount = this.state.properties_count;
        Object.keys(propertiesCount).forEach(p => {
            propertiesCount[p] = 0;
        })

        this.searchService.getSearchFilters(this, this.state.search_id, (response) => {

            response.property_families.forEach(family => {
                family.properties.forEach(property => {
                    property.values.forEach(value => {
                        propertiesCount[value.value_as_id] = value.count;
                    })
                })
            })

            this.setState({
                properties_count: propertiesCount
            })
        })
    }

    removeFilters()
    {
        this.searchService.clearFilters(this, this.state.search_id, (response) => {
            this.getSearchResults(this.state.search_id, 1, this.state.nb_results_per_page)
            this.updateFilterCount()

            this.setState({
                properties: {},
                nb_results: response.nb_results
            })
        })
    }

    applyCheckBoxFilter(propertyId, propertyValueId, selectedValue, minValue, maxValue, propertyCode)
    {
        var properties = this.state.properties;
        properties[propertyValueId] = !properties[propertyValueId];
        
        this.setState({
            properties: properties
        })

        var ckbs = document.getElementsByClassName("ckb_" + StringHelper.replaceAll(propertyCode, " ", "_"));
        for(var i = 0; i < ckbs.length; i++ )
        {
            if (ckbs[i].id != propertyValueId)
            {
                //document.getElementById(ckbs[i].id).enable = false;
                //$("#" + ckbs[i].id).prop("checked", false)

                //console.log($("#" + ckbs[i].id).is(':checked'))
            }
        }

        if (minValue == null && maxValue == null)
        {
            this.searchService.applyCkbFilters(this, this.state.search_id, propertyId, selectedValue, properties[propertyValueId], (response) => {

                // Mise à jour des résultats
                this.getSearchResults(this.state.search_id, 1, this.state.nb_results_per_page)

                this.updateFilterCount()

                this.setState({
                    nb_results: response.nb_results
                })
            })
        }
        else {
            var type = "cost_day";
            if (propertyId == "DISTANCE_PROPERTY")
            {
                type = "distance";
            }

            
            if (type == "distance")
            {
                /*if (properties[propertyValueId] == false)
                {
                    minValue = 0;
                    maxValue = 600000;   
                }

                this.applySliderFilter(type, minValue, maxValue)
                */
               this.applyMultiRangesFilter(type, minValue, maxValue)
            }
            else if (type == "cost_day")
            {
                this.applyMultiRangesFilter(type, minValue, maxValue)
            }
        }
    }

    applyMultiRangesFilter(type, minValue, maxValue)
    {
        this.searchService.applyMultiRangesFilter(this, this.state.search_id, type, minValue, maxValue, (response) => {

            // Mise à jour des résultats
            this.getSearchResults(this.state.search_id, 1, this.state.nb_results_per_page)

            this.updateFilterCount()

            this.setState({
                nb_results: response.nb_results
            })
        })
    }

    applySliderFilter(type, minValue, maxValue)
    {
        this.searchService.applySliderFilter(this, this.state.search_id, type, minValue, maxValue, (response) => {

            // Mise à jour des résultats
            this.getSearchResults(this.state.search_id, 1, this.state.nb_results_per_page)

            this.updateFilterCount()

            this.setState({
                nb_results: response.nb_results
            })
        })
    }

    hasFilters()
    {
        if (!this.state.properties)
        {
            return false;
        }
        else
        {
            var hasFilters = false;
            Object.keys(this.state.properties).forEach(p => {
                if (this.state.properties[p] && this.state.properties[p] === true)
                {
                    hasFilters = true;
                }
            })

            return hasFilters;
        }
    }

    startOrderWorkflow(resultId)
    {
        this.orderWorkflowRef.start(resultId)
    }

    startOrderWorkflowV2(resultId, dates, half_day)
    {
        this.orderWorkflowRef.startV2(resultId, dates, half_day)
    }

    renderNotAllowedAlert(searchRequest)
    {
        this.showModalNotification("infos", "", 
            <>
                Le service Truck2You n’est pas encore ouvert dans le département <strong style={{fontWeight:"bold"}}>{searchRequest.zip_code_light}</strong> pour le domaine <strong style={{fontWeight:"bold"}}>{searchRequest.domain.label}</strong>, mais nous y travaillons&nbsp;!
            </>
        )
    }

    getCheckBoxChecked(index, value)
    {
        return !this.state.specifiedPropertyValue && index == 0 ? true: value.code == this.state.specifiedPropertyValue
    }

    checkBoxChecked(e, value)
    {
        this.setState({specifiedPropertyValue: value.code})
    }

    renderCallForBidsPropertiesToSpecify(searchId, searchResponse, propertyValuesAsCode, propertyToSpecify)
    {
        const component = <div key="callForBidsPropertyModal" className="t2y-html-translation-container">
            {StringHelper.translateWithHtml(this, "Orders.bids_" + propertyToSpecify.code)}
            <br/>
            {
                propertyToSpecify.values.map((value, index) => {
                    return <>
                        <div key="callForBidsPropertyModalCkb" className="custom-control custom-radio custom-control-inline">
                            <input
                                id={"callForBidsPropertyModalCkb" + index}
                                className="custom-control-input"
                                name="customRadio"
                                type="radio"
                                checked={() => this.getCheckBoxChecked(index, value)}
                                onClick={(e) => this.checkBoxChecked(e, value)}
                            />
                            <label
                                className="custom-control-label t2y-height-auto"
                                htmlFor={"callForBidsPropertyModalCkb" + index}
                                style={{height:"auto !important"}}
                            >
                                {value.label}
                            </label>
                        </div>
                        <br/>
                    </>
                })
            }
        </div>


        var options = {
            key:"mqslkdqsmdlk",
            order: 2,
            hideTitle: true,
            hideIcon: true,
            component: component,
            okButtonTitle: this.state.specifiedPropertyValue,//StringHelper.translate(this, "Search.call_for_bids_accept"),
            cancelButtonTitle: StringHelper.translate(this, "Search.call_for_bids_refuse"),

            okButtonCallback: () => {
                propertyValuesAsCode = propertyValuesAsCode + "|" + this.state.specifiedPropertyValue + "|"
                this.callForBidsWorkflowRef.start(searchId, propertyValuesAsCode, searchResponse.allow_call_for_bids_next_day ? searchResponse.allow_call_for_bids_nb_days_to_add : 0)
            }
        }

        this.showModalConfirmation(options)
    }

    renderCallForBids(searchId, searchResponse)
    {
        const request = searchResponse.request;
        if (request.nb_results == 0 && (searchResponse.allow_call_for_bids || searchResponse.allow_call_for_bids_next_day))
        {   
            var options = {
                title: StringHelper.translate(this, "Search.call_for_bids_title"),
                large: true,
                hideIcon: true,
                leftComponent: <DefaultImage 
                    className={searchResponse.allow_call_for_bids_next_day ? "allow_call_for_bids_next_day_img_flow": "allow_call_for_bids_img_flow"}
                    src={require("../../../../assets/img/illustrations/AOV_ROD.svg").default}></DefaultImage>,
                component: <div className="t2y-html-translation-container">
                        {searchResponse.allow_call_for_bids? StringHelper.translateWithHtml(this, "Search.call_for_bids") : StringHelper.translateWithHtml(this, "Search.call_for_bids_next_day", null, {expected_first_day_as_string: searchResponse.expected_first_day_as_string})}
                </div>,
                okButtonTitle: searchResponse.allow_call_for_bids? StringHelper.translate(this, "Search.call_for_bids_accept") : StringHelper.translateWithHtml(this, "Search.call_for_bids_next_day_accept", null, {proposed_first_day_as_string: searchResponse.proposed_first_day_as_string}),
                cancelButtonTitle: searchResponse.allow_call_for_bids? StringHelper.translate(this, "Search.call_for_bids_refuse") : StringHelper.translateWithHtml(this, "Search.call_for_bids_next_day_refuse"),
                okButtonClassName: "t2y-secondary-button small",
                okButtonStyle: {paddingLeft:"5px", paddingRight:"5px"},
                cancelButtonClassName : "t2y-thirdparty-alt-button small",
                cancelButtonStyle: {paddingLeft:"5px", paddingRight:"5px"},
                okButtonCallback: () => {
                    // Vérification du nombre de valeurs pour chaque propriété
                    var propertyValuesToSpecify = null;
                    var propertyValuesAsCode = "";
                    request.properties.forEach((property, propertyIndex) => {
                        if (property.values.length > 1)
                        {
                            propertyValuesToSpecify = property;
                        }
                        else
                        {
                            propertyValuesAsCode = propertyValuesAsCode + "|" + property.values[0].code + "|"
                        }
                    })

                    // Si une propriété possède plusieurs valeur, on demande a l'utilisateur de la préciser
                    if (propertyValuesToSpecify)
                    {
                        //this.setState({specifiedProperty: propertyValuesToSpecify, specifiedPropertyValue: propertyValuesToSpecify.values[0].code})
                        //this.renderCallForBidsPropertiesToSpecify(searchId, searchResponse, propertyValuesAsCode, propertyValuesToSpecify)
                        this.callForBidsWorkflowRef.startPropertyChoice(searchId, propertyValuesAsCode, propertyValuesToSpecify)

                    }
                    else
                    {
                        // Lancement du process de connexion / création de compte
                        this.callForBidsWorkflowRef.start(searchId/*this.props.match.params.searchId*/, propertyValuesAsCode, searchResponse.allow_call_for_bids_next_day ? searchResponse.allow_call_for_bids_nb_days_to_add : 0)
                    }
                    
                }
            }

            this.showModalConfirmation(options)
        }
    }

    callForBidV2(searchId, typology, date, hour) {
        var options = {
            title: StringHelper.translate(this, "Search.call_for_bids_title"),
            large: true,
            hideIcon: true,
            leftComponent:<DefaultImage
                className={"allow_call_for_bids_img_flow"}
                src={require("../../../../assets/img/illustrations/AOV_ROD.svg").default}
            />,
            component: <div className="t2y-html-translation-container">
                {StringHelper.translateWithHtml(this, "Search.call_for_bids_later")}
            </div>,
            okButtonTitle: StringHelper.translate(this, "Search.call_for_bids_accept"),
            cancelButtonTitle: StringHelper.translate(this, "Search.call_for_bids_refuse"),
            okButtonClassName: "t2y-secondary-button small",
            okButtonStyle: {paddingLeft:"5px", paddingRight:"5px"},
            cancelButtonClassName : "t2y-thirdparty-alt-button small",
            cancelButtonStyle: {paddingLeft:"5px", paddingRight:"5px"},
            okButtonCallback: () => {
                this.callForBidsWorkflowRef.start(searchId, typology, 0)
            }
        }
        this.showModalConfirmation(options)
    }
    renderSearchForm()
    {
        return <PortalSearchForm embeded={true} childRef={elt => this.portalSearchForm = elt } style={{marginBottom: "20px"}} />
    }

    renderSearchFilters(slidingPanel)
    {
        return <Card className="" style={{width:"100%"}}>
            <CardBody style={{paddingLeft:0, paddingRight:0}}>
                <Col xl="12">
                    <Title4>
                        <FormattedMessage id="Search.filters_title"></FormattedMessage>
                    </Title4>
                </Col>
                {
                        (!slidingPanel && this.hasFilters()) ?
                        (
                            <Col xl="12">
                            <DefaultLink small={true} onClick={(elt) => {elt.preventDefault();this.removeFilters();return false;}} icon={Resources.button_delete_icon} className="t2y-thirdparty-alt-button small">Supprimer les filtres</DefaultLink>
                            </Col>
                        ) : (<></>)
                    }
                {
                    this.state.filter_families.map(family => {
                        return <>
                                {
                                    family.properties.map((property, familyIndex) => {
                                        if (property.values.length <= 1)
                                        {
                                            return <></>
                                        }

                                        return <>
                                            <hr className="t2y-search-filter-property-separator"/>
                                            <Col xl="12">
                                                    
                                                    <label className="t2y-search-filter-property-label">{property.label}</label>
                                            </Col>     
                                            <Col xl="12">
                                                    {
                                                        property.values.map((value, propertyIndex) => {
                                                            const propertyCount = this.state.properties_count[value.value_as_id];
                                                            const propertyCode = value.property_code;
                                                            var propertyValueId = value.value_as_id;
                                                            const propertyValueCode = value.value_as_code;
                                                            var propertyValueLabel = value.value_as_label;
                                                            var min = null;
                                                            var max = null;
                                                            var booleanValue = null;
                                                            var selectedValue = propertyValueId;
                                                            
                                                            if (property.code == "PRICE_PROPERTY")
                                                            {
                                                                var items = propertyValueLabel.split(':');
                                                                propertyValueLabel = "€" + items[0] + " - " + "€" + items[1];
                                                                min = items[0];
                                                                max = items[1];
                                                            }

                                                            if (property.code == "DISTANCE_PROPERTY")
                                                            {
                                                                var items = propertyValueLabel.split(':');
                                                                propertyValueLabel = items[0] + " - " + items[1] + " kms";
                                                                min = items[0];
                                                                max = items[1];
                                                            }

                                                            if (property.type_code == "PROPERTY_TYPE_BOOL")
                                                            {
                                                                selectedValue = propertyValueLabel
                                                                propertyValueLabel = StringHelper.translate(this, "PROPERTY_TYPE_BOOL" + propertyValueLabel)
                                                            }

                                                            const propertyValueDescription = null;
                                                            const inputId = propertyValueId ? propertyValueId : StringHelper.replaceAll(property.code, " ", "_") + "_" + propertyValueLabel
                                                            const checked = this.state.properties[propertyValueId]
                                                            return <>
                                                                <div key={propertyValueId} className={"custom-control custom-checkbox t2y-search-filter-container" + (checked ? "-checked" : "-not-checked")}>
                                                                    <input
                                                                        key={"fam_" + familyIndex + "_property_" + propertyIndex}
                                                                        checked={ checked ? "checked" : "" }
                                                                        className={"custom-control-input property-value-ckb " + "ckb_" + StringHelper.replaceAll(property.code, " ", "_")}
                                                                        id={inputId}
                                                                        data-code={propertyValueCode}
                                                                        data-label={propertyValueLabel}
                                                                        type="checkbox"
                                                                        onChange={(e) => {this.applyCheckBoxFilter(property.id, propertyValueId, selectedValue, min, max, property.code)} }
                                                                    />
                                                                    <label className="custom-control-label t2y-search-filter-property-ckb-label" htmlFor={inputId}>
                                                                    {propertyValueLabel} <Badge className="badge-md badge-circle badge-floating border-white t2y-search-filter-property-ckb-badge">{propertyCount}</Badge>
                                                                    </label>
                                                                    
                                                                </div>
                                                            </>
                                                        })
                                                    }
                                            </Col>
                                        </>
                                    })
                                }
                            
                        </>

                    })
                }

            </CardBody>
        </Card>
    }

    renderFilters()
    {
        return <>
            {this.renderSearchForm()}
            {this.renderSearchFilters(false)}
        </>
    }

    getMinDate(result)
    {
        return result.dates[0];
    }

    getDesktopVisibleComponentClass()
    {
        return "d-none d-lg-block";
    }

    getTabletVisibleComponentClass()
    {
        return "d-none d-md-block";
    }

    getMobileVisibleComponentClass()
    {
        return "d-block d-lg-none"
    }

    renderRating(rating, color)
    {
        return <Rating iconWidth="0.8em" iconHeight="0.8em" note={parseFloat(rating) / 2} type={"default"} color={color} />
    }

    renderEnvironmentalNote(rating, color)
    {
        // return <Rating iconWidth="0.8em" iconHeight="0.8em" note={parseFloat(rating) / 2}  type={"leaf"} color={color} />
        return <Rating iconWidth="0.8em" iconHeight="0.8em" note={rating}  type={"leaf"} color={color} />
    }

    renderSortIcon(key, label)
    {
        var currentValue = this.order;
        var items = currentValue.split('~');
        if (items[0] == key)
        {
            if(items[1] == "ASC")
            {
                return <><strong><i className="fas fa-sort-up"></i>&nbsp;{label}</strong></>
            }
            else
            {
                return <><strong><i className="fas fa-sort-down"></i>&nbsp;{label}</strong></>
            }
        }
        else
        {
            return label;
        }
    }

    renderPagination()
    {   
        const divideResult = this.state.nb_results / this.state.nb_results_per_page 
        const nbButtons = Math.floor(divideResult) + ((divideResult - Math.floor(divideResult)) > 0 ? 1 : 0);
        const nbButtonsToShow = 10;

        if (nbButtons <= 1)
        {
            return <></>
        }        
        else{
            var buttonIndexes = [];
            
            if (nbButtons > nbButtonsToShow)
            {

                var min = this.state.from - 5;
                if (min < 1)
                {
                    min = 1;
                }

                var max = this.state.from + 5;
                if (max > nbButtons)
                {
                    max = nbButtons;
                }

                if (min > 1)
                {
                    buttonIndexes.push(1);
                }
                if (min > 2)
                {
                    buttonIndexes.push("...");
                }

                // Premiers boutons
                for(var i = min; i <= max ; i++)
                {
                    buttonIndexes.push(i);
                }

                if (max < nbButtons - 1)
                {
                    buttonIndexes.push("...");
                }
                if (max < nbButtons)
                {
                    buttonIndexes.push(nbButtons);
                }
            }
            else
            {
                for(var i = 0; i < nbButtons; i++)
                {
                    buttonIndexes.push(i + 1);
                }    
            }

            
            return <nav >
                <Pagination  className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
                <PaginationItem className={this.state.from > 1 ?"":  "disabled"}>
                    <PaginationLink
                    href="#pablo"
                    onClick={e => this.getSearchResults(this.state.search_id, 1, this.state.nb_results_per_page, false)}
                    tabIndex="-1"
                    >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                    </PaginationLink>
                </PaginationItem>
                {
                    buttonIndexes.map(index => {
                        return <>
                            <PaginationItem className={(index == this.state.from ? "active" : "") + " " + (index == "..." ? "disabled": "")}>
                                <PaginationLink href="#pablo" onClick={e => this.getSearchResults(this.state.search_id, index, this.state.nb_results_per_page, false)}>
                                {index}
                                </PaginationLink>
                            </PaginationItem>
                        </>
                    })
                }
                
                <PaginationItem className={this.state.from < nbButtons ?"":  "disabled"}>
                    <PaginationLink
                    href="#pablo"
                    onClick={e => this.getSearchResults(this.state.search_id, nbButtons, this.state.nb_results_per_page, false)}
                    >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                    </PaginationLink>
                </PaginationItem>
                </Pagination>
            </nav> 
        }
    }

    renderSummary()
    {
        if (this.state.request)
        {
            var placeLabel = null;
            if (this.state.request.address.city != null && this.state.request.address.city != "")
            {
                placeLabel = this.state.request.address.city;
            }
            else
            {
                placeLabel = this.state.request.address.summary.toLowerCase();
            }

            //var label = this.props.intl.formatMessage({id:"Search.results_title"}, {place: placeLabel}); 
            var subLabel = this.props.intl.formatMessage({id:"Search.results_subtitle"}, {number: this.state.nb_results}); 
            
            return <>
            {/*
                <Title2 content={label} className={"m-2 alt text-justify " + this.getDesktopVisibleComponentClass()}></Title2>
                <Title3 content={label} className={"m-2 alt text-justify " + this.getMobileVisibleComponentClass()}></Title3>
            */}
                <Title4 content={subLabel} className="m-2 alt text-justify"></Title4>
                {
                    (this.state.request.extended) ?
                    (   
                        <Message type="infos">
                            <FormattedMessage id="Search.results_extended_message"></FormattedMessage>
                        </Message>
                    ) : (<></>)
                }
            </>
        }
        else {
            return <></>
        }
    }

    renderSortingCriteria()
    {
        if (!this.state.results || (this.state.results && this.state.results.length == 1))
        {
            return <></>
        }
        else
        {
            return <>
                <Col xs="12" className={"d-block d-lg-none mb-3 " + this.getMobileVisibleComponentClass() }>
                    <DefaultInput type="select">
                        <option key="sort_by_best" value="best" onClick={() => this.sortResults("best", "ASC")}>{StringHelper.translate(this, "Search.results_order_best")}</option>
                        <option key="sort_by_dist" value="distance" onClick={() => this.sortResults("distance", "ASC")}>{StringHelper.translate(this, "Search.results_order_distance")}</option>
                        <option key="sort_by_cost_day" value="cost_day" onClick={() => this.sortResults("cost_day", "ASC")}>{StringHelper.translate(this, "Search.results_order_price")}</option>
                        <option key="sort_by_rating" value="rating" onClick={() => this.sortResults("rating", "DESC")}>{StringHelper.translate(this, "Search.results_order_rating")}</option>
                    </DefaultInput>
                </Col>
            
                <Col xs="12"  className={"justify-content-center " + this.getDesktopVisibleComponentClass()}>
                    <ButtonGroup className="btn-group-toggle t2y-search-sort-group" data-toggle="buttons" style={{borderColor: "white", width:"100%"}}>
                    <Button style={{width:"25%"}} className={classnames({ active: this.field !== "best" })} color="secondary" onClick={() => this.sortResults("best", "ASC")}>
                        <input
                        autoComplete="off"
                        name="options"
                        type="radio"
                        value={this.field === "best"}
                        />
                        <div class={WebsiteHelper.onlyVisibleOnLarge()}>
                            <FormattedMessage id="Search.results_order_best"></FormattedMessage>
                        </div>
                        <div class={WebsiteHelper.onlyVisibleOnMiddle()}>
                            <DefaultImage src={Resources.plus}></DefaultImage> <FormattedMessage id="Search.results_order_best_light"></FormattedMessage>
                        </div>
                    </Button>
                    <Button style={{width:"25%"}} className={classnames({ active: this.field !== "distance" })} color="secondary" onClick={() => this.sortResults("distance", "ASC")}>
                        <input
                        autoComplete="off"
                        name="options"
                        type="radio"
                        value={this.field === "distance"}
                        />
                        <div class={WebsiteHelper.onlyVisibleOnLarge()}>
                            <FormattedMessage id="Search.results_order_distance"></FormattedMessage>
                        </div>
                        <div class={WebsiteHelper.onlyVisibleOnMiddle()}>
                            <DefaultImage src={Resources.plus}></DefaultImage> <FormattedMessage id="Search.results_order_distance_light"></FormattedMessage>
                        </div>
                    </Button>
                    <Button style={{width:"25%"}} className={classnames({ active: this.field !== "cost_day"})} color="secondary" onClick={() => this.sortResults("cost_day", "ASC")}>
                        <input
                        autoComplete="off"
                        name="options"
                        type="radio"
                        value={this.field === "cost_day"}
                        />
                        <div class={WebsiteHelper.onlyVisibleOnLarge()}>
                            <FormattedMessage id="Search.results_order_price"></FormattedMessage>
                        </div>
                        <div class={WebsiteHelper.onlyVisibleOnMiddle()}>
                            <DefaultImage src={Resources.minus}></DefaultImage> <FormattedMessage id="Search.results_order_price_light"></FormattedMessage>
                        </div>
                    </Button>
                    <Button style={{width:"25%"}} className={classnames({ active: this.field !== "rating"})} color="secondary" onClick={() => this.sortResults("rating", "DESC")}>
                        <input
                        autoComplete="off"
                        name="options"
                        type="radio"
                        value={this.field === "rating"}
                        />
                        <div class={WebsiteHelper.onlyVisibleOnLarge()}>
                            <FormattedMessage id="Search.results_order_rating"></FormattedMessage>
                        </div>
                        <div class={WebsiteHelper.onlyVisibleOnMiddle()}>
                            <DefaultImage src={Resources.best}></DefaultImage> <FormattedMessage id="Search.results_order_rating_light"></FormattedMessage>
                        </div>
                    </Button>
                    </ButtonGroup>
                </Col>
        </>
        }
    }

    renderExtendedLabel(label, extended, classToAdd)
    {
        return <label className={"t2y-search-result-label " + (extended == "1" ? "" : classToAdd)}>
            {label}
        </label>
    }

    renderResults()
    {
        return <>
            <Col xs="12">
                {
                    ((this.state.results && this.state.results.length > 0) || this.state.showLoadingResultModal) ? 
                    (
                        (this.state.results) ?
                        (
                            <>
                            {this.state.results.map(result => {
                                var realCost = result.amount_to_pay_ht;
                                var automaticValidationColor = "green";
                                var automaticValidationText = "Réservation instantanée";

                                if(result.transporter_automatic_validation == false)
                                {
                                    automaticValidationColor = "orange";
                                    automaticValidationText = "Disponibilité à confirmer";
                                }

                                return <div className="t2y-search-result-container" style={{position:"relative"}} key={StringHelper.createDomElementId()}>

                                    <div className="d-none d-md-block t2y-ribbon t2y-ribbon-bottom-right"><span className={automaticValidationColor + " pl-2"}>{automaticValidationText}</span></div>
                                    <div className="d-block d-md-none t2y-ribbon t2y-ribbon-bottom"><span className={automaticValidationColor}>{automaticValidationText}</span></div>

                                    <Row>
                                        <Col xs="12" className="d-block d-md-none">
                                        {this.renderExtendedLabel(
                                            <><Title4 content={result.truck_label}></Title4></>,
                                            result.strict_truck,
                                            "t2y-search-result-extended-truck"
                                        )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="3" sm="3" className="d-none d-md-block">
                                            <img alt="" className="img-center  shadow shadow-lg--hover " 
                                            src={Config.bndApi + "/truck/image/" + result.truck_id} 
                                            style={{width: "100%", height: "auto"}} />
                                        </Col>
                                        <Col xs="12" sm="12" md="9">
                                            <Row>
                                                <Col xs="6">
                                                    <Row>
                                                        <Col xs="12" className="d-none d-md-block">
                                                        {this.renderExtendedLabel(
                                                            <><Title4 content={result.truck_label}></Title4></>,
                                                            result.strict_truck,
                                                            "t2y-search-result-extended-truck"
                                                        )}
                                                        </Col>
                                                    </Row>
                                                    {/*
                                                        (result.transporter_checked) ?
                                                        (
                                                        <Row><Col xs="12">
                                                            <Row>
                                                                    <Col xs="1"><DefaultImage className="t2y-search-result-certified" src={require("../../../../assets/img/icons/certified_transporter.svg").default}></DefaultImage></Col> 
                                                                    <Col xs="10">
                                                                        <label className={"t2y-search-result-label"}>
                                                                        <FormattedMessage id="Search.detail_verify_transporteur" />
                                                                        </label>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        ) : (<></>)
                                                    */}
                                                    <Row>
                                                        <Col xs="12">
                                                            <div style={{float:"left"}} className="mr-2">
                                                                <label className="t2y-search-result-label">Note qualité :</label>
                                                            </div>
                                                            <div style={{float:"left"}}>
                                                                {this.renderRating(result.rating, "#FFD433")}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row><Col xs="12">
                                                            <Row>
                                                                <Col xs="1"><DefaultImage className="t2y-search-result-check" src={require("../../../../assets/img/icons/check.svg").default}></DefaultImage></Col> 
                                                                <Col xs="10">
                                                                    {this.renderExtendedLabel(
                                                                        <>à {Math.round(result.distance)} Km</>,
                                                                        result.strict_distance,
                                                                        "t2y-search-result-extended-distance"
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row> 
                                                    <Row>
                                                        <Col xs="12">
                                                            <Row>
                                                                <Col xs="1"><DefaultImage className="t2y-search-result-check" src={require("../../../../assets/img/icons/check.svg").default}></DefaultImage></Col> 
                                                                <Col xs="10">
                                                                    {this.renderExtendedLabel(
                                                                        (
                                                                            !result.strict_amount ? 
                                                                                <>Toute la journée du <DateLabel date={result.dates[0]} label={false}></DateLabel></> : 
                                                                                <>Disponibilité le <DateLabel date={result.dates[0]} label={false}></DateLabel></>
                                                                        ),
                                                                        result.strict_date,
                                                                        "t2y-search-result-extended-date"
                                                                    )}
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row><Col xs="12">
                                                        <Row>
                                                            <Col xs="1">
                                                            <DefaultImage className="t2y-search-result-check" src={require("../../../../assets/img/icons/check.svg").default}></DefaultImage>
                                                            </Col>
                                                            <Col xs="10">
                                                            <div style={{float:"left"}} className="mr-2">
                                                                <label className="t2y-search-result-label">Note éco : </label>
                                                            </div>
                                                            <div style={{float:"left"}}>
                                                            {this.renderEnvironmentalNote(result.environmental_note, "#80FF33")}
                                                            </div>
                                                            </Col>
                                                        </Row>
                                                        </Col>
                                                    </Row>

                                                </Col>
                                                <Col xs="6">
                                                    <Row>
                                                        <Col xs="12" className="d-block d-md-none">
                                                            <img alt="" className="img-center  shadow shadow-lg--hover " 
                                                            src={Config.bndApi + "/truck/image/" + result.truck_id} 
                                                            style={{width: "100%", height: "auto"}} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12"  className={"mb-3 pr-0 pl-0 " + (!result.strict_amount ? "t2y-search-result-extended-amount" : "")} >
                                                            <Title2  content={StringHelper.formatAmountWithPattern(realCost, "€", "{amount} {devise} HT")}></Title2>
                                                            <em className="t2y-search-result-other-prices" style={{position:"absolute", marginTop:-14}}>forfait total</em>
                                                        </Col>
                                                        <Col sm="12">
                                                            <span className="t2y-search-result-other-prices">heure supp : {result.cost_hour_as_string}</span>
                                                        </Col>
                                                        <Col sm="12">
                                                            <span className="t2y-search-result-other-prices">km supp : {result.cost_km_as_string}</span> 
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12">
                                                            <DefaultButton className="t2y-secondary-button small" onClick={(e) => {this.startOrderWorkflow(result.id)}}>
                                                                <FormattedMessage id="Search.results_see_detail"></FormattedMessage>
                                                            </DefaultButton>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>

                                            </Row>

                                        </Col>
                                    </Row>
                                    
                                </div>
                            })
                            }
                            {this.renderPagination()}
                            </>
                        ) : (<></>)

                    ) :
                    (<></>)


                }
            </Col>

                {/*
            <Card>
                    <CardHeader className="border-0">
                    <h3 className="mb-0">{this.state.nb_results} Résultat(s)</h3>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xl="12">
                                {
                                    ((this.state.results && this.state.results.length > 0) || this.state.showLoadingResultModal) ? 
                                    (
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                <th scope="col">{this.renderSortIcon("rating", "Satisfaction")}</th>
                                                <th scope="col">{this.renderSortIcon("cost_day", "Prix")}</th>
                                                <th scope="col">{this.renderSortIcon("distance", "Distance")}</th>
                                                <th scope="col">Km Supp</th>
                                                <th scope="col">H Supp</th>
                                                <th scope="col">{this.renderSortIcon("environmental_note", "Note env.")}</th>
                                                <th scope="col" />
                                                </tr>
                                            </thead>
                                            
                                            <tbody className="list">
                                            {
                                                (this.state.results) ?
                                                (
                                                    this.state.results.map(result => {
                                                        return <tr className="m-1">
                                                            <td>
                                                            {this.renderRating(result.rating, "#FFD433")}
                                                            </td>
                                                            <td>{result.cost_day} €</td>
                                                            <td>{Math.round(result.distance)} Km</td>
                                                            <td>{result.cost_km} €</td>
                                                            <td>{result.cost_hour} €</td>
                                                            <td>{this.renderEnvironmentalNote(result.environmental_note, "#80FF33")}</td>
                                                            <td>
                                                                <Button type="button" color="secondary" onClick={(e) => {this.startOrderWorkflow(result.id)}}><i className="fa fa-search" />Voir le détail</Button>
                                                            </td>
                                                        </tr>
                                                    })
                                                ) : ("")
                                            }
                                            </tbody>
                                        </Table>
                                    )
                                    :
                                    (
                                        <Row className="text-center">
                                            <Col xl="12">
                                            <Alert color="warning">
                                            <strong>Désolé,</strong> aucun camion disponible pour votre recherche...
                                            </Alert>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="py-4">
                    {this.renderPagination()}
                    </CardFooter>
                </Card>
                            */}

        </>
    }

    renderContentWithResults()
    {
        return <>
            <Row>
                <Col lg="4" className={this.getDesktopVisibleComponentClass()}>
                    <Row>
                        {this.renderFilters()}
                    </Row>
                </Col>
                <Col className={this.getMobileVisibleComponentClass()} xs="12">
                    
                    {
                        (!this.state.slidingFiltersPanelIsVisible) ?
                        (
                            <>
                                <DefaultButton  onClick={() => this.setState({slidingFiltersPanelIsVisible: true})} className="t2y-secondary-button t2y-fab">
                                    <DefaultImage src={Resources.button_filter_icon}></DefaultImage>
                                </DefaultButton>
                            </>
                            
                        ):(<></>)
                    }
                    <PortalSearchForm embeded={true} childRef={elt => this.portalSearchForm2 = elt } style={{marginBottom: "20px", width:"100%"}} />

                    <ReactSlidingPane
                        key="sliding-filter-panel"
                        from={'left'}
                        shouldCloseOnEsc={true}
                        closeIcon={
                        <>
                            {
                                (this.hasFilters())?
                                (
                                    <DefaultButton className="t2y-link-small" style={{padding: 5}} onClick={(elt) => {elt.preventDefault();this.removeFilters();return false;}} icon={Resources.button_delete_icon} className="t2y-thirdparty-alt-button small">Supprimer les filtres</DefaultButton>
                                ) : (<></>)
                            }
                            <DefaultButton style={{padding: 5}} icon={Resources.button_list_icon} className="t2y-secondary-button small">Afficher les résutats</DefaultButton>
                        </>}
                        //hideHeader={options.hideHeader}
                        className={"t2y-sliding-filters"}
                        //overlayClassName={options.overlayClassName}
                        isOpen={this.state.slidingFiltersPanelIsVisible}
                        //title={options.title}
                        //subtitle={options.subtitle}
                        width={"100%"}
                        //style={{overflow:"scroll"}}
                        onRequestClose={() => this.setState({slidingFiltersPanelIsVisible: false})}
                    >
                         {this.renderSearchFilters(true)}
                    </ReactSlidingPane>
                </Col>
                {
                    
                    (this.state.request && this.state.nb_results == 0) ?
                    (
                        <>
                            <Col md="8" style={{paddingLeft: "5%"}}>
                                <Message type="infos">Nous n'avons trouvé aucun résultat pour votre recherche.</Message>
                            </Col>
                        </>
                    ) :
                    (
                        <Col lg="8" style={{paddingLeft: "5%"}}>
                            <Row>
                                {this.renderSummary()}
                            </Row>
                            <Row className="justify-content-center">
                                {this.renderSortingCriteria()}
                            </Row>
                            <Row>
                                {this.renderResults()} 
                            </Row>
                        </Col>  
                    )
                }  
            </Row>      
        </>
    }

    startOrder(searchResult) {
        if(searchResult.callForBirds) {
            this.callForBidV2(searchResult.searchId, searchResult.typology, searchResult.date, searchResult.hour,)
        }
        else {
            this.startOrderWorkflowV2(searchResult.result_id, searchResult.dates, !searchResult.half_day)
        }
    }

    render() {
        if(!this.state.search_id) {
            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString)
            const category = urlParams.get('category')
            return <>
                {this.renderParent()}
                <CallForBidsWorkflow childRef={elt => this.callForBidsWorkflowRef = elt} id="CALL_FOR_BIDS_WORKFLOW"></CallForBidsWorkflow>
                <OrderWorkflow childRef={(elt) => {
                    this.orderWorkflowRef = elt
                }} id="ORDER_WORKFLOW"></OrderWorkflow>
                {category && <Search startOrder={this.state.startOrder} setStartOrder={this.startOrder} selectedCategory={category}/>}
                {!category && <OfferOnline startOrder={this.state.startOrder} setStartOrder={this.startOrder}/>}
            </>
        }
        return  (
            <>
                {this.renderParent()}
                <CallForBidsWorkflow childRef={elt => this.callForBidsWorkflowRef = elt} id="CALL_FOR_BIDS_WORKFLOW"></CallForBidsWorkflow>

                <OrderWorkflow childRef={(elt) => this.orderWorkflowRef = elt} id="ORDER_WORKFLOW"></OrderWorkflow>
                <FormLoadingIndicator loading={this.state.loading}></FormLoadingIndicator>
                {
                    (this.state.search_id) ?
                    (
                        (this.securityService.isConnected()) ?
                        (
                            <>
                                <SimpleHeader name="" description="" icon=" " />
                                <Container className="mt--6">
                                    <Row>
                                    <Col className="order-xl-1" xl="12">
                                    <Card>
                                        <Row className="t2y-search-result-connected-container">
                                        <Col xl="12">
                                    {this.renderContentWithResults()}
                                    </Col>
                                    </Row>
                                    </Card>
                                    </Col>
                                    </Row>
                                </Container>
                            </>
                        )
                        :
                        (
                            <>
                                <Container>
                                {this.renderContentWithResults()}
                                </Container>
                            </>
                        )
                    ) :
                    (
                        <>
                        <SimpleHeader name="!!!" description="" icon=" " />
                        <Container className="mt--6">
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                <Card>
                                    <Row>
                                    <Col xl="12">
                                        <PortalSearchForm embeded={false} childRef={elt => this.portalSearchForm = elt } style={{marginBottom: "20px"}} />
                                    </Col>
                                    </Row>
                                </Card>
                                </Col>
                            </Row>
                        </Container>
                        </>
                    )
                }
            </>
        );
    }
}


export default injectIntl(SearchResult)


