
import React from "react";
import classnames from "classnames";
import $ from "jquery"

// reactstrap components
import {
    Button,
    Container,
    Form,
    Table,
    FormGroup,
    Modal,
    UncontrolledPopover,
    UncontrolledTooltip,
    PopoverBody,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Alert,
    InputGroup,
    InputGroupAddon,
    InputGroupText
  } from "reactstrap";
import Spinner from 'react-spinners/ClipLoader'
import Resources from "../../../resources";
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";
import FormInput from '../components/FormInput'
import Title3 from '../../components/common/title3'
import Title4 from '../../components/common/title4'
import DefaultPopover from '../../components/common/defaultPopover'
import DefaultCheckbox from '../../components/common/defaultCheckbox'
import FormValidationComponent from "../components/FormValidationComponent";
import FormLoadingIndicator from "../components/FormLoadingIndicator.jsx";
import FormGroupInput from '../components/form/FormGroupInput'
import FormHourInput from '../components/form/FormHourInput'
import TransporterService from "../../../proxies/TransporterService"
import SecurityService from "../../../proxies/SecurityService"
import FormCivilityInput from "../components/form/FormCivilityInput";
import ReactDatetime from "react-datetime";
import { injectIntl , FormattedMessage } from 'react-intl';
import StringHelper from "../../../helpers/StringHelper";
import DefaultButton from "../../components/common/defaultButton";
import DefaultImage from "../../components/common/defaultImage";
import NoTruckDriverAlert from "../../components/business/noTruckDriverAlert";
import DateHelper from "../../../helpers/DateHelper";
import { lb } from "date-fns/locale";
import DefaultLabel from "../../components/common/defaultLabel";
import WebsiteHelper from "../../../helpers/WebsiteHelper";
import ArrayHelper from "../../../helpers/ArrayHelper";
import { element } from "prop-types";
import PlanningLegend from "../../../components/PlanningLegend/PlanningLegend";


var moment = require('moment');
require('moment/locale/fr');

class Planning extends FormValidationComponent {

    constructor(props)
    {
        super(props)
        this.transporterService = new TransporterService();
        this.securityService = new SecurityService();

        this.validationRules = {
            booking_limit_time: {
                required: false
            }

        }

        this.firstCellWidth = null
        this.daysCkbRefs = {}
        this.state = {
            loading : false,
            trucks: [],
            drivers: [],
            transporters: [],
            trucks_drivers: [],
            eventModalIsOpen: false,
            date : moment(),
            nbDays: this.getDaysInMonth(moment()),
            event_start_date: moment(),
            event_end_date: moment(),
            event_enable: false,
            event_driver_id: null,
            event_truck_id: null,
            planningEnable: false,
            truckDriverAlert: false,

            selecting: false,
            startDate: null,
            startIndex: null,
            endDate: null,
            endIndex: null,
            selectedTable: null,
            selectedObject: null,
            firstCellWidth: null,
            datesLoading: []
        }

    }

    refreshCompany(callback)
    {
        var company = this.transporterService.getCompany();
        if (company.nb_completed_trucks == 0 || company.nb_drivers == 0 || company.nb_driver_truck_links == 0)
        {
            // Les données sont vide, on rafraichi avec un me
            this.securityService.me(this, (response) => {
                // On rafraichi la page
                callback(response.company);
            })
        }
        else
        {
            callback(company)
        }
    }

    componentWillMount()
    {
        // Récupération des données du parc
        this.refreshCompany((company) => {
            if (company.nb_completed_trucks == 0)
            {
                this.setState({truckDriverAlert: true})
            }
            else
            {
                // Récupération des règles de planning
                this.transporterService.getPlanning(this, this.state.date, (response => {
                    this.bindData(response);

                    this.setState({planningEnable: true})
                }));
            }
        })
    }

    componentDidMount()
    {
        document.addEventListener("keydown", this.escapeCallback.bind(this), false);
        document.addEventListener("mouseup", this.mouseUp.bind(this), false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escapeCallback.bind(this), false);
        document.addEventListener("mouseup", this.mouseUp.bind(this), false);
    }

    mouseUp()
    {
        this.endSelection(this.state.selectedTable, null, null)
    }


    escapeCallback(event){
        if(event.keyCode === 27) {
            //Do whatever when esc is pressed
            this.clearSelection()
        }
    }

    beginSelection(tableId, obj, date, index){
        this.setState({
            selectedObject: obj,
            selecting: true,
            startDate: date,
            startIndex: index,
            endDate: date,
            endIndex: index,
            selectedTable: tableId
        })
        this.updateSelection(tableId, date, index)
    };

    clearSelection()
    {
        this.setState({
            selectedObject: null,
            selecting: false,
            startDate: null,
            startIndex: null,
            endDate: null,
            endIndex: null,
            selectedTable: null
        })
    }

    askForAction(tableId, startDate, endDate)
    {
        // Préparation de la données de retour
        var businessRules = this.state.rules;
        var result = {
            tableId: tableId,
            choiceRequired: false,
            nbEnable: 0,
            nbDisable: 0,
            hasDayRules: false,
            nbExpectedDates: 0,
            nbRealDates: 0,
            realDates: [],
            dayRules: {
                monday: businessRules.open_monday,
                tuesday: businessRules.open_tuesday,
                wednesday: businessRules.open_wednesday,
                thursday: businessRules.open_thursday,
                friday: businessRules.open_friday,
                saturday: businessRules.open_saturday, 
                sunday: businessRules.open_sunday
            }
        }

        var dateInfos = null;
        
        const expectedDates = DateHelper.findDaysBetween2Dates(startDate, endDate, []);
        result.nbExpectedDates = expectedDates.length;

        // Selection de plusieurs dates
        if (result.nbExpectedDates == 1)
        {   
            var matchDay = null
            this.state.selectedObject.days.forEach((businessDay, index) => {
                if (DateHelper.equals(expectedDates[0], businessDay))
                {
                    matchDay = businessDay
                }
            })

            const dayOfWeekInfos = DateHelper.getDayOfWeek(matchDay);
            dateInfos = this.getDateStateInfos("", matchDay);
            // Cas d'un jour non ouvré
            if (result.dayRules[(dayOfWeekInfos.english)] == false)
            {
                result.hasDayRules = true
                result.dayRules[(dayOfWeekInfos.english)] = true
            }

            result.nbRealDates += 1
            result.realDates.push(matchDay)
            result.nbEnable += (dateInfos.enable ? 1 : 0)
            result.nbDisable += (dateInfos.enable ? 0 : 1)
        }
        else if (result.nbExpectedDates  > 1)
        {
            expectedDates.forEach((d, index) => {
                var matchDay;
                this.state.selectedObject.days.forEach((businessDay, index) => {
                    if (DateHelper.equals(d, businessDay))
                    {
                        matchDay = businessDay;

                        // matchDay fait il parti des règle des jours ouvrés
                        const dayOfWeekInfos = DateHelper.getDayOfWeek(matchDay);
                        if (result.dayRules[(dayOfWeekInfos.english)] == false)
                        {
                            matchDay = null;
                        }
                    }
                })

                if (matchDay != null)
                {
                    dateInfos = this.getDateStateInfos("", matchDay);
                    result.nbRealDates += 1
                    result.realDates.push(matchDay)
                    result.nbEnable += (dateInfos.enable ? 1 : 0)
                    result.nbDisable += (dateInfos.enable ? 0 : 1)
                }
                
            })
        }

        if (result.nbEnable > 0 && result.nbDisable > 0)
        {
            result.choiceRequired = true
        }
        else
        {
            result.enable = result.nbDisable > 0
        }

        return result;
    }

    endSelection(tableId, date, index){
        // Si apres un escape, la selection n'existe plus alors on ne fait rient
        if (this.state.selecting == false)
        {
            return false;
        }
        else
        {
            this.daysCkbRefs = {}

            const updateSelectionInfos = this.updateSelection(tableId, date, index)
            
            this.setState({
                selecting: false,
                //endDate: date,
                //endIndex: index
            });

            // On récupère les données concernant la selection
            var askForActionInfos = this.askForAction(tableId, updateSelectionInfos.startDate.date, updateSelectionInfos.endDate.date)

            // Préparation de l'evenement à sauvegarder
            var externalEvent = {
                start_date: DateHelper.toString(updateSelectionInfos.startDate, 'YYYY-MM-DD'),
                end_date: DateHelper.toString(updateSelectionInfos.endDate, 'YYYY-MM-DD'),
                driver_id: this.state.event_driver_id,
                truck_id: this.state.event_truck_id,
                transporter_id: this.state.event_transporter_id,
                driver_id: this.state.selectedObject.driver_id,
                truck_id: this.state.selectedObject.truck_id,
                transporter_id: this.state.selectedObject.type == "transporter" ? this.state.selectedObject.id : null,
                monday: askForActionInfos.dayRules.monday,
                tuesday: askForActionInfos.dayRules.tuesday,
                wednesday: askForActionInfos.dayRules.wednesday,
                thursday: askForActionInfos.dayRules.thursday,
                friday: askForActionInfos.dayRules.friday,
                saturday: askForActionInfos.dayRules.saturday,
                sunday: askForActionInfos.dayRules.sunday,
            }

            // Prépération des libellés pour la période
            const startDateAsString = DateHelper.toString(updateSelectionInfos.startDate, 'DD/MM/YYYY')
            const endDateAsString = DateHelper.toString(updateSelectionInfos.endDate, 'DD/MM/YYYY')

            // Si il y a un nombre de jours selectionné mais qu'aucun ne peut être mis à jour alors on informe l'utilisateur
            if (askForActionInfos.nbExpectedDates > 0 && askForActionInfos.nbRealDates == 0)
            {
                this.showWarningToastNotification("Attention", "Les dates selectionnées ne peuvent pas être modifiées en une seule fois. Merci de les modifier en les selectionnant une par une.")

                // On efface la selection et on sort de la fonction
                this.clearSelection()
                return false
            }
            // Si dans la période selectionnée, des dates sont disponible et non disponible alors on demande quoi faire
            else if (askForActionInfos.choiceRequired)
            {
                var modalOptions = {
                    hideIcon: true,
                    component: <>
                        <label>Que souhaitez vous faire pour la période du {startDateAsString} au {endDateAsString}</label>
                    </>,
                    onEscapeClose: () => {this.closeModal();this.clearSelection()},
                    okButtonTitle: 'Rendre disponible',
                    cancelButtonTitle: 'Rendre indisponible',
                    okButtonCallback: () => {
                        externalEvent.enable = true;
                        this.setState({datesLoading: askForActionInfos.realDates})
                        
                        this.transporterService.createEvent(null, externalEvent, (response) => {
                            // TODO Passage des dates selectionner en mode chargement (avant affichate de la page mise à jour)
                            this.setState({datesLoading: []})
                            this.clearSelection()
                            this.dateChanged(this.state.date)
                        }, (httpResponse) => {
                            this.setState({ loading: false, datesLoading: [] })
                            httpResponse.json().then((apiResponse) => { this.showErrorFromResponse( apiResponse,null );});
                        })
                    },
                    cancelButtonCallback: () => {
                        externalEvent.enable = false;
                        this.setState({datesLoading: askForActionInfos.realDates})
                        
                        this.transporterService.createEvent(null, externalEvent, (response) => {
                            // TODO Passage des dates selectionner en mode chargement (avant affichate de la page mise à jour)
                            this.setState({datesLoading: []})
                            this.clearSelection()
                            this.dateChanged(this.state.date)
                        }, (httpResponse) => {
                            this.setState({ loading: false, datesLoading: [] })
                            httpResponse.json().then((apiResponse) => { this.showErrorFromResponse( apiResponse,null );});
                        })
                    }
                }

                this.showModalConfirmation(modalOptions)
            }
            // Sinon passage automatique de la période dans l'état inverse de celui en cours
            else // Passage automatique  
            {
                externalEvent.enable = askForActionInfos.enable;

                this.setState({datesLoading: askForActionInfos.realDates})
                this.transporterService.createEvent(null, externalEvent, (response) => {
                    // TODO Passage des dates selectionner en mode chargement (avant affichate de la page mise à jour)                
                    this.setState({datesLoading: []})
                    this.clearSelection()
                    this.dateChanged(this.state.date)
                }, (httpResponse) => {
                    this.setState({ loading: false, datesLoading: [] })
                    httpResponse.json().then((apiResponse) => { this.showErrorFromResponse( apiResponse,null );});
                })
            }

            return null;
        }
        
        
        
        /*
        if (askForActionInfos.choiceRequired || askForActionInfos.hasDayRules)
        {
            // Création d'un évènement
            var dayOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
            var modalOptions = {
                hideIcon: true,
                component: <>
                    <label>Que souhaitez vous faire pour la période du {startDateAsString} au {endDateAsString}</label>
                    {
                        (askForActionInfos.hasDayRules) ?
                        (
                            <>
                                <div style={{textAlign:"left"}}>
                                <label>Merci de préciser si les jours suivant doivent être modifiés ?</label>
                                <Row>
                                    <Col xs="3"></Col>
                                    <Col xs="6">
                                    {
                                        dayOfWeek.map((d, index) => {
                                            return <>
                                                {
                                                    (askForActionInfos.dayRules[d] == false) ?
                                                    (
                                                        <>
                                                            <DefaultCheckbox childRef={(elt) => this.daysCkbRefs[d] = elt} right={true} content={StringHelper.translate(this, "Default." + d)}></DefaultCheckbox>
                                                        </>
                                                    ): (<></>)
                                                }
                                            </>
                                        })
                                    }
                                    </Col>
                                </Row>
                                </div>
                            </>
                        )
                        :
                        (
                            <></>
                        )
                    }
                </>,
                okButtonTitle: 'Rendre disponible',
                cancelButtonTitle: 'Rendre indisponible',
                okButtonCallback: () => {
                    externalEvent.enable = true;
                    Object.keys(this.daysCkbRefs).forEach(function(key) {
                        externalEvent[key] = this.daysCkbRefs[key].getValue()
                    }.bind(this))
                    
                    this.transporterService.createEvent(this, externalEvent, (response) => {
                        this.showSuccessToastNotification('', "La disponibilité à bien été prise en compte pour la période du " + startDateAsString + " au " +  endDateAsString + ".")
                        this.clearSelection()
                        this.dateChanged(this.state.date)
                    }, (httpResponse) => {
                        this.setState({ loading: false })
                        httpResponse.json().then((apiResponse) => { this.showErrorFromResponse( apiResponse,null );});
                    })

                },
                cancelButtonCallback: () => {
                    externalEvent.enable = false;
                    Object.keys(this.daysCkbRefs).forEach(function(key) {
                        externalEvent[key] = this.daysCkbRefs[key].getValue()
                    }.bind(this))
                    
                    this.transporterService.createEvent(this, externalEvent, (response) => {
                        this.showSuccessToastNotification('', "L' indisponibilité à bien été prise en compte pour la période du "+ startDateAsString + ' au ' +  endDateAsString + ".")
                        this.clearSelection()
                        this.dateChanged(this.state.date)
                    }, (httpResponse) => {
                        this.setState({ loading: false })
                        httpResponse.json().then((apiResponse) => { this.showErrorFromResponse( apiResponse,null );});
                    })
                }
            }

            this.showModalConfirmation(modalOptions)
        }
        else // Passage automatique  
        {
            externalEvent.enable = askForActionInfos.enable;
            this.transporterService.createEvent(this, externalEvent, (response) => {
                this.showSuccessToastNotification('', (externalEvent.enable ? "La disponibilité" : "L' indisponibilité") + " à bien été prise en compte pour la période du " + startDateAsString + " au " +  endDateAsString + ".")
                this.clearSelection()
                this.dateChanged(this.state.date)
            }, (httpResponse) => {
                this.setState({ loading: false })
                httpResponse.json().then((apiResponse) => { this.showErrorFromResponse( apiResponse,null );});
            })
        }*/

        
    };
    
    updateSelection(tableId, date, index) {
        if (this.state.selecting) {
            var startDate = this.state.startDate
            var startIndex = this.state.startIndex
            var endDate = this.state.endDate
            var endIndex = this.state.endIndex

            if (index != null)
            {
                if (index <= startIndex)
                {
                    startIndex = index
                    startDate = date
                }
                else
                {
                    endDate = date;
                    endIndex = index
                }
            }

            var newState = {
                startIndex: startIndex,
                startDate: startDate,
                endIndex: endIndex,
                endDate: endDate
            }

            this.setState(newState);

            return newState
        }
    };

    getDateStateInfos(tableId, date, nb, previous = null, next = null)
    {
        var value = {
            nbToShow: nb,
            state: null,
            subState: null,
            enable: true,
            orderId: null
        }

        if (date.order_id)
        {
            var error = false;
            var backgroundColor = "green";
            var orderContent = date.order_number;
            if (date.order_state_id == this.state.order_to_validate_state.id)
            {
                backgroundColor = "orange";
                orderContent = date.order_number + " à valider";
            }
            else if (date.mission_truck_id && !date.mission_driver_id) 
            {
                backgroundColor = "red";
                error = true;
                orderContent = date.order_number + " : Pas de conducteur affecté !";
            }

            //value.nbToShow = <DefaultImage src={Resources.orders_logo} style={{fontSize:"20px", color:"green" }} />
            var orderStyle = {overflow: "hidden", color:"white", backgroundColor:backgroundColor, height:30, position:"relative", float:"left", bottom:0, left:"-5%", width:"110%" }
            var text = "";
            
            if (previous != null && previous.order_id != date.order_id)
            {
                orderStyle.borderTopLeftRadius = "10px";
                orderStyle.borderBottomLeftRadius = "10px";
                //text = <label style={{color:"white", overflow:"hidden"}}>{date.order_number}</label>
                text = <DefaultPopover targetId={tableId + "_" + date.order_id + DateHelper.toString(date, "YYYYMMDD")} logo={!error ? Resources.orders_logo : Resources.toast_warning_logo} 
                    content={orderContent} 
                    style={{position:"relative", marginTop:12, color:"white", backgroundColor:backgroundColor}}>
                </DefaultPopover>
            }
            else if (error)
            {
                text = <DefaultPopover targetId={tableId + "_" + date.order_id + DateHelper.toString(date, "YYYYMMDD")} logo={Resources.toast_warning_logo} 
                    content={orderContent} 
                    style={{position:"relative", marginTop:12, color:"white", backgroundColor:backgroundColor}}>
                </DefaultPopover>
            }            


            if (next != null && next.order_id != date.order_id)
            {
                orderStyle.borderTopRightRadius = "10px";
                orderStyle.borderBottomRightRadius = "10px";
            }

            
            if (date.order_id)
            {
                value.nbToShow = <div style={orderStyle} className="t2y-clickable" onClick={e => {WebsiteHelper.goToOrder(this, date.order_id, false)}}>{text}</div>
            }
            else
            {
                value.nbToShow = <div style={orderStyle}>{text}</div>
            }
            value.enable = false;
            value.orderId = date.order_id
        }

        if (date.available)
        {
            // Jour disponible pour un evenement exceptionnel
            if (date.external_event_id != null || date.external_events != null || date.driver_external_event_id != null || date.truck_external_event_id != null)
            {
                value.state = "AVAILABLE_EVENT"
                //subState = (date.driver_external_event_id ? "DRIVER" : "") + (date.truck_external_event_id ? "TRUCK" : "")
            }
            else
            {
                value.state = "AVAILABLE"
            }
        }
        else
        {
            // Jour disponible pour un evenement exceptionnel
            if (date.external_event_id != null || date.external_events != null || date.driver_external_event_id != null || date.truck_external_event_id != null)
            {
                value.state = "NOT_AVAILABLE_EVENT"
                /*if (tableId == "truck_driver" && date.order_id)
                {
                    if (
                        (date.driver_external_event_id && !date.truck_external_event_id) ||
                        (!date.driver_external_event_id && date.truck_external_event_id)
                    )
                    {
                        value.state = "CLOSED"
                        value.subState = (date.driver_external_event_id ? "DRIVER" : "") + (date.truck_external_event_id ? "TRUCK" : "")    
                    }
                }*/
            }
            else if (date.public_holidays)
            {
                // Sinon si le jour est un jour férié
                value.state = "PUBLIC_HOLIDAY"
            }
            else
            {
                // Sinon jour fermé issu des règles
                value.state = "CLOSED"
            }
        }

        if (["NOT_AVAILABLE", "PUBLIC_HOLIDAY", "NOT_AVAILABLE_EVENT", "CLOSED"].includes(value.state) && !date.order_id)
        {
            value.nbToShow = "";
            value.enable = false;
        }


        return value;
    }


    getDaysInMonth(selectedDate)
    {
        return new Date(selectedDate.year(), selectedDate.month() + 1, 0).getDate();   
    }

    bindData(response)
    {
        this.setState({
            order_to_validate_state: response.order_to_validate_state,
            loading: false,
            rules: response.rules.instance,
            trucks: response.trucks,
            drivers: response.drivers,
            trucks_drivers: response.trucks_drivers,
            transporters: response.transporter
        })
    }

    nextMonth()
    {
        this.dateChanged(
            moment(DateHelper.addMontToDate(1, this.state.date))
        );
    }

    previousMonth()
    {
        this.dateChanged(
            moment(DateHelper.addMontToDate(-1, this.state.date))
        );
    }

    dateChanged(selectedDate)
    {
        this.transporterService.getPlanning(this, selectedDate, (response => {
            this.setState({
                order_to_validate_state: response.order_to_validate_state,
                date : selectedDate,
                nbDays: this.getDaysInMonth(selectedDate),
                trucks: response.trucks,
                drivers: response.drivers,
                trucks_drivers: response.trucks_drivers,
                transporters: response.transporter
            })
        }));
    }

    toggleEventModal(obj, selectedDate, enable)
    {
        const isOpen = this.state.eventModalIsOpen;
     
        var newState = {
            eventModalIsOpen: !isOpen
        }
        
        if (newState.eventModalIsOpen)
        {
            newState.event_start_date = moment(selectedDate.date)
            newState.event_end_date = moment(selectedDate.date)
            newState.event_enable = !enable
            newState.event_driver_id = obj.driver_id
            newState.event_truck_id = obj.truck_id
            newState.event_transporter_id = obj.type == "transporter" ? obj.id : null
        }

        this.setState(newState)
    }

    onSubmitEvent(e)
    {
        e.preventDefault();
        var postedDatas = {
            start_date: this.state.event_start_date != null ? this.state.event_start_date.format('YYYY-MM-DD') : null,
            end_date: this.state.event_end_date != null ? this.state.event_end_date.format('YYYY-MM-DD') : null,
            enable: this.state.event_enable,
            driver_id: this.state.event_driver_id,
            truck_id: this.state.event_truck_id,
            transporter_id: this.state.event_transporter_id
        }

        this.transporterService.createEvent(this.submitEventButtonRef, postedDatas, (response) => {
            this.confirmMessage("Enregistrement effectué avec succès")
            this.toggleEventModal(null, null, null)
            this.dateChanged(this.state.date)
        })
    }

    renderCell(obj, date, state, nb, key)
    {
        var nbToShow = nb;
        var enable = true;
        if (["NOT_AVAILABLE", "NOT_AVAILABLE_EVENT", "CLOSED"].includes(state))
        {
            nbToShow = "";
            enable = false;
        }
        
        return <td key={key} style={{cursor:"pointer"}} className={state} 
            onClick={(e) => (["NOT_AVAILABLE", "CLOSED"].includes(state) == false ? this.toggleEventModal(obj, date, enable) : e.preventDefault())}>
            {nbToShow}
        </td>
    }

	hideMousePosition(element) {
		// Remise à la normal des en tête et titre surlignés
		$(".planning-header-date").removeClass("planning-header-date-selected")
		$(".planning-line-title").removeClass("planning-header-date-selected")
		$(".planning-td").removeClass("planning-td-hover")

		// Suppression des lignes si elles existent
		var straightLine = element.querySelector('.straightLine');
		var hrLine = element.querySelector('.hrLine');

		if (straightLine) {
			element.removeChild(straightLine);
		}


		if (hrLine) {
			element.removeChild(hrLine);
		}
	}

    highlightMousePosition(event, show) {
		var element = document.getElementById('planning-table').parentNode;
		var elementPosition = WebsiteHelper.getElementPosition(element)

		var headers = document.getElementById('planning-headers');
		var headersPosition = WebsiteHelper.getElementPosition(headers)

		var firstPlanningCell = document.getElementById("first-planning-cell");
		var firstPlanningCellPosition = WebsiteHelper.getElementPosition(firstPlanningCell)

		var scroll = WebsiteHelper.getScroll();

		var x = event.pageX;
		var y = event.pageY;

        var straightLine = element.querySelector('.straightLine');
        var hrLine = element.querySelector('.hrLine');

		if (show) {
			// Mise en surbrillance des entête de colonne et des titres des lignes
			$(".planning-header-date").removeClass("planning-header-date-selected")
			$(".planning-line-title").removeClass("planning-header-date-selected")
			$(".planning-td").removeClass("planning-td-hover")

			// Mise en surbrillance de la date 
			var dates = document.getElementsByClassName("planning-header-date")
			var colFind = false;
			var rowFind = false;
			var selectedTdPosition = null;
			Array.prototype.forEach.call(dates, function (item) {
				var itemPosition = WebsiteHelper.getElementPosition(item);
				if ((itemPosition.defaultRight) > (x - scroll.left) && (itemPosition.defaultLeft) < (x - scroll.left)) {
					$(item).addClass("planning-header-date-selected")
					colFind = true;
				}
			});


			// Mise en surbrillance du titre de la ligne
			var titles = document.getElementsByClassName("planning-line-title")
			Array.prototype.forEach.call(titles, function (item) {
				var itemPosition = WebsiteHelper.getElementPosition(item);
				if ((itemPosition.defaultBottom) > (y - scroll.top) && (itemPosition.defaultTop) < (y - scroll.top)) {
					$(item).addClass("planning-header-date-selected")
					rowFind = true
				}
			});


			// Récupération de la céllule sous la souris
			var cells = document.getElementsByClassName("planning-td")
			Array.prototype.forEach.call(cells, function (item) {
				var itemPosition = WebsiteHelper.getElementPosition(item);
				if (
					(itemPosition.defaultBottom) > (y - scroll.top) && (itemPosition.defaultTop) < (y - scroll.top) &&
					(itemPosition.defaultRight) > (x - scroll.left) && (itemPosition.defaultLeft) < (x - scroll.left)
				) {
					$(item).addClass("planning-td-hover")
					selectedTdPosition = itemPosition;
				}
			});

            // Si un colonne et une ligne sont trouvées alors on trace les traits
			if (colFind && rowFind) {
				const lineWidth = 3;
				const mousePadding = -1;

				const xDelta = x - selectedTdPosition.defaultLeft - scroll.left;
				const yDelta = y - selectedTdPosition.defaultTop - scroll.top;
				const halfHeight = parseInt(selectedTdPosition.height / 2);
				const halfWidth = parseInt(selectedTdPosition.width / 2);

				const hrLineTop = selectedTdPosition.defaultTop + halfHeight
				const straightLeft = selectedTdPosition.defaultLeft + halfWidth

				// Ligne verticale
				if (!straightLine) {
					straightLine = document.createElement('div');
					straightLine.classList.add('straightLine');
					element.appendChild(straightLine);
				}
				straightLine.style.height = (y + mousePadding - scroll.top - headersPosition.defaultBottom + 10 - yDelta) + "px";;
				straightLine.style.width = (lineWidth) + 'px';
				straightLine.style.top = (headersPosition.defaultBottom - 10) + "px";
				straightLine.style.left = straightLeft + "px" //(x + mousePadding - scroll.left)+ "px";

				// Ligne horizontale
				if (!hrLine) {
					hrLine = document.createElement('div');
					hrLine.classList.add('hrLine');
					element.appendChild(hrLine);
				}
				hrLine.style.height = lineWidth + "px";
				hrLine.style.width = (x + mousePadding - scroll.left - elementPosition.left - firstPlanningCellPosition.width + 10 - xDelta) + "px";
				hrLine.style.left = (elementPosition.left + firstPlanningCellPosition.width - 10) + "px";
				hrLine.style.top = hrLineTop + "px"//(y + mousePadding  - scroll.top - halfHeight)+ "px";
            }
            else
            {
                this.hideMousePosition(element)
            }
        }
        else
        {
            this.hideMousePosition(element)
        }
    }

	renderCellAlt(obj, date, nb, key, index, tableId, linePosition, tableLinePosition = 0) {
		var title = null;
		if (nb != null && nb != "") {
			var infoItems = nb.split("|");
			nb = infoItems[0];
			title = infoItems[1];
		}

		var previous = index > 0 ? obj.days[index - 1] : null;
		var next = index < (obj.days.length - 1) ? obj.days[index + 1] : null;
		var stateInfos = this.getDateStateInfos(tableId, date, nb, previous, next)
		var state = stateInfos.state;
		var subState = stateInfos.subState;
		var nbToShow = stateInfos.nbToShow
		var enable = stateInfos.enable;

		var isLoading = this.state.selectedTable == tableId && this.state.selectedObject.id == obj.id && this.state.datesLoading.find(x => DateHelper.equals(x, date))

		var className = state + " " + subState + " " +
			(
				this.state.selectedTable && this.state.selectedTable == tableId && this.state.selectedObject.id == obj.id && index >= this.state.startIndex && index <= this.state.endIndex ? "selected" : ""
			)


		if (isLoading) {
			className = "DATE_LOADING";
		}

		className = className + " line-" + tableLinePosition + " col-" + index

		/*
		if (nb == "0")
		{
				className = "CLOSED"
				nbToShow = ""
		}*/

		const id = StringHelper.createDomElementId();
		return <td id={id} title={title} key={key} style={{ cursor: "pointer", width: "auto", height: "40px", borderTop: (linePosition == 0 ? "none" : "auto") }}
			className={"planning-td " + className}
			onMouseDown={() => {
				if (!date.order_id) {
					this.beginSelection(tableId, obj, date, index)
				}
			}}
			onMouseUp={() => {
				if (!date.order_id) {
					this.endSelection(tableId, date, index)
				}
			}}
			onMouseMove={(e) => {
				if (!date.order_id) {
					this.updateSelection(tableId, date, index)
				}
			}}>
			{
				(isLoading) ?
					(
						<><Spinner size={10}></Spinner></>
					) :
					(
						<>
							{nbToShow}


							{
								(date.external_events || date.public_holidays || date.driver_external_events || date.truck_external_events) ?
									(
										<DefaultPopover
											targetId={tableId + "_" + key}
											content={
												(date.order_number ? date.order_number : "") +
												(date.external_events ? date.external_events : "") +
												(date.driver_external_events ? " " + date.driver_external_events : "") +
												(date.truck_external_events ? " " + date.truck_external_events : "") +
												(date.public_holidays ? " " + date.public_holidays : "")}
											style={{ position: "relative", marginTop: 12, }}
										>
										</DefaultPopover>
									) : (<></>)
							}
						</>
					)

			}



			{/*
                (["AVAILABLE_EVENT", "NOT_AVAILABLE_EVENT"].includes(state)) ?
                (
                    (state == "AVAILABLE_EVENT") ?
                    (
                        <>&nbsp;<i className="text-success far fa-calendar-check fa-2x"></i></>
                    )
                    :
                    (
                        <i className="text-danger far fa-calendar-times fa-2x"></i>
                    )
                    
                ) : ("")*/

			}

		</td>
	}

    renderTransporterCells(transporter, index, linePosition, tableLinePosition)
    {
        transporter.type = "transporter"
        return transporter.days.map((day, dayIndex) => {
            return this.renderCellAlt(transporter, day, "", index + "_" + dayIndex, dayIndex, "transporter", linePosition, tableLinePosition)
        })
    }

    renderTuckDriverCells(truckDriver, index, linePosition, tableLinePosition)
    {
        truckDriver.type = "truck_driver"
        truckDriver.id = StringHelper.createNewGuid()
        return truckDriver.days.map((day, dayIndex) => {
            return this.renderCellAlt(truckDriver, day, "", index + "_" + dayIndex, dayIndex, "truck_driver", linePosition, tableLinePosition)
        })
    }

    renderTruckCells(truck, index, linePosition)
    {
        truck.type = "truck"
        truck.id = StringHelper.createNewGuid()
        return truck.days.map((day, dayIndex) => {
            return this.renderCellAlt(truck, day, day.nb_drivers, index + "_" + dayIndex, dayIndex, "truck", linePosition)
        })
    }

    renderDriverCells(driver, index, linePosition)
    {
        driver.type = "driver"
        driver.id = StringHelper.createNewGuid()
        return driver.days.map((day, dayIndex) => {
            return this.renderCellAlt(driver, day, day.nb_trucks, index + "_" + dayIndex, dayIndex, "driver", linePosition)
        })
    }

    renderCompany(tableLinePosition)
    {
        return  this.state.transporters.map((transporter, index) => {
            return <tr key={"transporter" + index}>
                <td style={{borderTop: (index == 0 ? "none" : "auto"), textAlign:"left", paddingLeft:"25px"}} className={"planning-line-title line-" + tableLinePosition}>
                    {this.securityService.getCompany().company_name}
                </td>
                    {this.renderTransporterCells(transporter, "company" + index, index, tableLinePosition)}
                
            </tr>
        })
    }

    renderTrucksDrivers(tableLinePosition)
    {
        if (!this.state.trucks_drivers || this.state.trucks_drivers.length == 0)
        {
            return <></>
        }
        else
        {
            return <>
                {this.renderTableTitle("Couples véhicule/conducteur")}
                <tbody>
                {
                    this.state.trucks_drivers.map((truckDriver, index) => {
                        return <tr key={"transporter" + index}>
                            <td style={{borderTop: (index == 0 ? "none" : "auto"), textAlign:"left", paddingLeft:"25px"}} className={"planning-line-title line-" + tableLinePosition} >
                                {truckDriver.truck_summary + " / " + truckDriver.driver_summary}
                            </td>
                                {this.renderTuckDriverCells(truckDriver, "truck_driver" + index, index, tableLinePosition)}
                            
                        </tr>
                    })
                }
                </tbody>
            </>
        }  
    }

    countItemSummary(itemSummary) {
		var str = itemSummary;
		var count = str.split(',').length;

		return count
	}

    renderTrucks(title, trucks, prefix)
    {
        if (trucks.length == 0)
        {
            return <></>
        }
        
        return <>
            {this.renderTableTitle(title)}
            <tbody>
            {
                trucks.map((truck, index) => {
                    return <tr key={prefix + "trucks" + index} className={truck.complete == false ? "t2y-warning-content" : ""}>
                        <td style={{borderTop: (index == 0 ? "none" : "auto"), textAlign:"left", paddingLeft:"25px"}} className="planning-line-title">
                            <div style={{display:"flex"}}><p style={{overflow:"hidden",maxWidth:150,fontSize:13,textOverflow:"ellipsis", margin:0}}>
                                {truck.truck_reference}</p>
                                {
                                    (truck.drivers_summary) ?
                                    (
                                        <>
                                        <i className="fas fa-info-circle ml-2" id={prefix + "truck" + index} />
                                        <UncontrolledTooltip placement="top" target={prefix + "truck" + index}>
                                            Peut être conduit par {this.countItemSummary(truck.drivers_summary) > 1 ? (this.countItemSummary(truck.drivers_summary) + " conducteurs : ") : ""} {truck.drivers_summary}
                                        </UncontrolledTooltip>
                                        </>
                                    ) :("")
                                }
                            </div>
                        </td>
                            {this.renderTruckCells(truck, "truck" + index, index)}
                        
                    </tr>
                })
            }
            </tbody>
        </>
    }

    renderAllTrucks()
    {
        if (!this.state.trucks || this.state.trucks.length == 0)
        {
            return <></>
        }
        else
        {
            const completedTrucks = ArrayHelper.find(this.state.trucks, (truck) => { return truck.complete == true});
            const notCompletedTrucks = ArrayHelper.find(this.state.trucks, (truck) => { return truck.complete == false});

            return <>
                {this.renderTrucks("Véhicules publiés", completedTrucks, "complete")}
                {this.renderTrucks("Véhicules en brouillon", notCompletedTrucks, "not_complete")}
            </>
        }
    }

    renderDrivers()
    {
        if (!this.state.drivers || this.state.drivers.length == 0)
        {
            return <></>
        }
        else
        {
            return <>
                {this.renderTableTitle("Conducteurs")}
                <tbody>
                {
                    this.state.drivers.map((driver, index) => {
                        return <tr key={"drivers" + index}>
                            <td style={{borderTop: (index == 0 ? "none" : "auto"), textAlign:"left", paddingLeft:"25px"}} className="planning-line-title" >
                                {driver.driver_first_name} {driver.driver_last_name}
                                {
                                    (driver.trucks_summary) ?
                                    (
                                        <>
                                        <i className="fas fa-info-circle ml-2" id={"driver" + index} />
                                        <UncontrolledTooltip placement="top" target={"driver" + index}>
                                            Peut conduire {this.countItemSummary(driver.trucks_summary) > 1 ? this.countItemSummary(driver.trucks_summary) + " véhicules : " : ""} {driver.trucks_summary}
                                        </UncontrolledTooltip>
                                        </>
                                    ) :("")
                                }
                            </td>
                            {this.renderDriverCells(driver, "driver" + index, index)}
                            
                        </tr>
                    })
                }
                </tbody>
            </>
        }
    }

    renderHeaders()
    {
        return <thead style={{backgroundColor:"white"}} className="planning-headers" id="planning-headers">
            
            <tr className="planning-headers default">
                <th className="planning-header first-planning-cell" id="first-planning-cell">#</th>
                {this.renderHeadersDaysOfTheWeek()}
            </tr>

            
        </thead>
    }
    renderHeadersDates()
    {
        var days =[]
        for(var i=0; i < this.state.nbDays; i++)
        {
            days.push(i + 1);
        }

        return days.map(day => {
            return <th className="planning-header">
                {day.toString().padStart(2, "0")}/{(this.state.date.month() + 1).toString().padStart(2, "0")}
            </th>
        })
        
    }

    renderHeadersDaysOfTheWeek()
    {
        var days =[]
        for(var i=0; i < this.state.nbDays; i++)
        {
            days.push(i + 1);
        }
        return days.map(day => {
            var dateAsString = (this.state.date.month() + 1).toString().padStart(2, "0") + "/" + day.toString().padStart(2, "0") + "/" + this.state.date.year()
            var date = new Date(dateAsString)
            var dayAsString = date.toLocaleDateString("fr", { weekday: 'short' })


            return <th className="planning-header planning-header-date" style={{textTransform: "capitalize"}}>
                {StringHelper.capitalize(dayAsString.substr(0, 3))}<br/>{day.toString().padStart(2, "0")}
            </th>
        })
    }

    renderEventModal(){
        return <Modal
              className="modal-dialog-centered" size="md" isOpen={this.state.eventModalIsOpen} toggle={() => this.toggleEventModal(null, null, null)}>
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-transparent pb-5">
                      {
                          (this.state.event_enable) ?
                          (
                            <label>Ajouter une <strong>disponibilité</strong></label>
                          ):
                          (
                            <label>Ajouter une <strong>indisponibilité</strong></label>
                          )
                      }
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form" onSubmit={e => this.onSubmitEvent(e)} >

                        <FormGroupInput type="multiline" htmlFor="event_start_date" label={"Date de début"}>
                          <ReactDatetime style={{border : "none"}} defaultValue={this.state.event_start_date} 
                            multiple={false} 
                            closeOnSelect={true} id="event_start_date" 
                            timeFormat={false} dateFormat="DD/MM/YYYY" 
                            locale={"fr-FR"} onChange={(e) => { this.setState({event_start_date: e})}} />
                        </FormGroupInput>
                        <FormGroupInput type="multiline" htmlFor="event_end_date" label={"Date de fin"}>
                          <ReactDatetime style={{border : "none"}} defaultValue={this.state.event_start_date} multiple={false} closeOnSelect={true} 
                            id="event_end_date" timeFormat={false}  
                            dateFormat="DD/MM/YYYY" 
                            locale={"fr-FR"} onChange={(e) => { this.setState({event_end_date: e})}} />
                        </FormGroupInput>
                        <FormGroupInput type="multiline" htmlFor="description" label={"Commentaire"}>
                            <Input type="textarea"  rows="6"  id="event_description" name="event_description" placeholder={"Commentaire..."}  />
                        </FormGroupInput>
                      
                        <div className="text-center">
                            <DefaultButton
                            childRef={elt => this.submitEventButtonRef = elt}
                            className="t2y-secondary-button"
                            color="primary"
                            type="submit"
                            > Enregistrer
                            </DefaultButton>
                        </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>
    }

    renderTableTitle(title, tooltip)
    {
        return <tbody><tr style={{borderTopColor: "var(--gris-fonce)", borderTopWidth: "2px", borderTopStyle: "solid"}}>
            <td colSpan="32" style={{width:"100%", textAlign:"left", paddingTop:0, paddingBottom:0}}>
                <DefaultLabel>{title}</DefaultLabel>
                {tooltip &&
					<>
						<i className="fas fa-info-circle ml-2" id={"companyTitleToolTip"} />
						<UncontrolledTooltip placement="right" target={'companyTitleToolTip'}>
							<p>Le planning global de votre société permet de fermer d'un coup tous les véhicules (congés par exemple) ou de forcer leur ouverture (travail un jour férié par exemple)</p>
						</UncontrolledTooltip>
					</>
                }
            </td>
        </tr></tbody>
    }

    renderDateSelection()
    {
        return <FormGroup>
            <InputGroup
            className={classnames({
                focused: this.state.default && this.state.default.dollar
            })}
            >
                <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{cursor:"pointer"}} onClick={() => this.previousMonth()}>
                        <DefaultImage src="fas fa-arrow-circle-left" style={{fontSize:20}}></DefaultImage>
                    </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime 
                        inputProps={{readOnly:true, style:{color:"var(--gris-fonce)", backgroundColor:"white", cursor:"pointer", textTransform:"capitalize", textAlign:"center"}}}
                        value={this.state.date} style={{border : "none"}} 
                        multiple={false} closeOnSelect={true} 
                        id="planning_filter" 
                        dateFormat="MMMM YYYY" 
                        timeFormat={false} 
                        locale={"fr-FR"} onChange={this.dateChanged.bind(this)} />
                <InputGroupAddon addonType="append">
                    <InputGroupText style={{cursor:"pointer"}} onClick={() => this.nextMonth()}>
                        <DefaultImage src="fas fa-arrow-circle-right" style={{fontSize:20}}></DefaultImage>
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </FormGroup>
    }

    render() {
        if (this.state.planningEnable == false && this.state.truckDriverAlert == false)
        {
            return <><FormLoadingIndicator loading={true}></FormLoadingIndicator></>
        }
        else if (this.state.truckDriverAlert)
        {
            return <NoTruckDriverAlert context="planning"></NoTruckDriverAlert>
        }
        else 
        {
            return  (
                <>
                {this.renderParent()}
                {this.renderEventModal()}
                {this.state.notifications && this.state.notifications.length > 0 &&
                    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', width: '100%', display: 'flex', justifyContent: 'center', height: '100%', padding: '30px', zIndex: 10, position: 'absolute', top: 0 }} >
                        <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', borderRadius: '8px', padding: '20px', height: '280px', marginTop: '50px', flexDirection: 'column', width: '530px', boxShadow: '-3px 5px 15px -2px #000000' }} >
                            <div style={{ textAlign: 'center' }}>
                                <h2>Avant de gérer vos disponibilités, veuillez définir votre politique commerciale en cliquant sur ce lien :</h2>
                            </div>
                            <div>
                                <DefaultButton
                                    onClick={() => WebsiteHelper.goTo(this, "/admin/commercial")}
                                    className="t2y-secondary-button"
                                    color="primary"
                                    type="submit"
                                > Mes règles commerciales
                                </DefaultButton></div>
                            </div>
                    </div>
                }
                <Container onWheel={(e) => this.highlightMousePosition(e, false)} className="mt--4" fluid id="main-container">
                    <Form className="needs-validation" noValidate >
                        <Row>
                            <Col className="order-xl-1" xl="12">
                            <FormLoadingIndicator loading={this.state.loading}>
                            <Card>
                                <CardBody>
                                    <Row>
                                    <Col lg="12">
                                        <div className="pl-lg-4">
                                            <Row>
                                            <Col lg="4" style={{ display: "flex", alignItems: "center", flexDirection:"column" }}>
                                                <h3 style={{alignSelf:"flex-start"}}>Mode d'emploi:</h3>
                                                <ul aria-label="Mode d'emploi:" style={{alignSelf:"flex-start"}}>
                                                    <li style={{ fontSize: 12 }}> Cliquez sur une case du planning pour changer son état</li>
                                                    <li style={{ fontSize: 12 }}> Sélectionnez plusieurs cases pour changer leur état d'un coup</li>
                                                </ul>
                                            </Col>
                                            <Col lg="4">
                                                {this.renderDateSelection()}
                                            </Col>
                                            <Col lg="4"></Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12" className="planning-container">
                                                    <Table id="planning-table" responsive className="planning-table" style={{overflow:"hidden !important"}}
                                                        onMouseMove={(e) => this.highlightMousePosition(e, true)} 
                                                        onMouseOut={(e) => this.highlightMousePosition(e, false)} 
                                                    >
                                                        {this.renderHeaders()}
                                                        {this.renderTableTitle("Planning d’ouverture global", true)}
                                                        <tbody>
                                                        {this.renderCompany(1)}
                                                        </tbody>
                                                        
                                                        {this.renderTrucksDrivers(2)}
                                                        {this.renderAllTrucks()}
                                                        {this.renderDrivers()}
                                                    </Table>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <PlanningLegend/>
                                            </Row>
                                        </div>
                                    </Col>
                                    </Row>
                                            
                                </CardBody>
                            </Card>
                            </FormLoadingIndicator>
                            </Col>

                            </Row>
                        </Form>
                    


                    
                    </Container>
            </>
            );
        }
    }
}


export default injectIntl(Planning)